<div class="container bg-basic-1" *ngIf="{ currentTab: currentTab$ | async, totalTabs: totalTabs$ | async, editAdData: editAdData$ | async } as vm">
	<mat-toolbar class="justify-content-between bg-basic-1 header px-0">
		<div>
			<span class="fg-general-5 fw-700 fs-32px lh-36px me-3 cursor-pointer align-middle" (click)="navigateAway()" *ngIf="!viewport.mobile">
				{{ 'n3786' | translations }}
			</span>

			<ng-container *ngIf="hasBusinessAccount">
				<!-- Selected account -->
				<button
					mat-icon-button
					[matMenuTriggerFor]="menu"
					class="p-0 m-0 place-ad-header-select align-middle"
					*ngIf="currentSellerId$ | async"
					[disabled]="
						vm?.currentTab === vm?.totalTabs ||
						(vm?.editAdData?.adPayment?.status === paymentStatus?.PAID && vm?.editAdData?.adStatus?.status !== adStatus.SOLD)
					"
				>
					<img
						findqoAvatarImage
						[ngSrc]="selectedAccount?.imageUrl | avatarImagePipe"
						alt="User account photo"
						class="business-information rounded-pill object-fit-cover"
						[alt]="selectedAccount?.name"
						width="45"
						height="30"
					/>

					<span class="fg-general-5 text-body-regular mx-2 align-middle d-inline-block text-truncate selected-account">
						{{ selectedAccount?.name }}
					</span>

					<div class="d-inline-block align-middle">
						<mat-icon svgIcon="expand-more" class="fg-general-4"></mat-icon>
					</div>
				</button>

				<mat-menu #menu="matMenu" class="place-ad-header-menu-dropdown">
					<div class="h-50px p-3 pe-none place-ad-menu-item">
						<span class="text-body-regular fg-general-5">{{ 'n3118' | translations }}</span>
					</div>

					<button mat-menu-item class="h-60px place-ad-menu-item" *ngFor="let item of accounts" (click)="onSelectedAccount(item, true)">
						<div class="row mx-0 align-items-center">
							<div class="col-auto me-2">
								<img
									[ngSrc]="item?.imageUrl | avatarImagePipe"
									width="30"
									height="30"
									alt="User account photo"
									class="account-photo image-width rounded-pill object-fit-cover"
									findqoAvatarImage
								/>
							</div>

							<div class="col px-0 overflow-hidden">
								<span class="fg-general-5 text-body-regular d-block text-truncate mw-200px">{{ item?.name }}</span>
								<span class="fg-general-3 text-caption-regular">
									{{ item.sellerTypeId === '1' ? ('n2967' | translations) : ('n2966' | translations) }}
								</span>
							</div>

							<div class="col-auto px-0" *ngIf="item?.isSelectedAccount">
								<mat-icon svgIcon="check-2" class="m-0"></mat-icon>
							</div>
						</div>
					</button>

					<!-- On click user will be navigated to the my profile, business account page -->
					<button mat-menu-item class="h-60px" *ngIf="accounts.length !== 2" [routerLink]="'place-ad/business-account'">
						<div class="d-inline-block align-middle create-button rounded-pill me-2">
							<mat-icon svgIcon="add"></mat-icon>
						</div>

						<span class="fg-prim-2 text-body-regular">{{ 'n3119' | translations }}</span>
					</button>
				</mat-menu>
			</ng-container>
		</div>

		<div class="align-self-center">
			<findqo-button
				id="intercom-chat"
				class="fg-general-4"
				[btnType]="buttonType.BASIC"
				[label]="'n3073' | translations"
				[iconPrefix]="'help-outline'"
				[btnClass]="'text-body-regular fg-general-4 me-2'"
				(clicked)="showIntercom()"
			></findqo-button>

			<button mat-icon-button class="close-btn rounded-circle align-middle" *ngIf="viewport.mobile" (click)="navigateAway()">
				<mat-icon svgIcon="close-error-3" class="justify-content-center align-items-center"></mat-icon>
			</button>
		</div>
	</mat-toolbar>
</div>
<mat-divider></mat-divider>
