import { ResolveFn, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, catchError, forkJoin, map, of, tap, throwError } from 'rxjs';
import { AgentsApi } from '@core/data-access/apis/agents.api';
import { AgentsState } from '@shared/states/agents.stats';
import { inject } from '@angular/core';
import { ConfigState } from '@core/data-access/states/config.state';
import { ProfileApi } from '@core/data-access/apis/profile.api';
import { PlaceAdFacade } from '../place-ad.facade';
import { APP_ROUTE } from '@core/constants';

export const PlaceAdPageResolver: ResolveFn<any> = (
	route: ActivatedRouteSnapshot,
	state: RouterStateSnapshot,
	router: Router = inject(Router),
	agentsApi: AgentsApi = inject(AgentsApi),
	agentsState: AgentsState = inject(AgentsState),
	configState: ConfigState = inject(ConfigState),
	profileApi: ProfileApi = inject(ProfileApi),
	placeAdFacade: PlaceAdFacade = inject(PlaceAdFacade)
): Observable<any> => {
	return forkJoin({
		//get user data
		userData: placeAdFacade.getUser()?.businessProfile
			? of(placeAdFacade.getUser())
			: profileApi.getProfile().pipe(
					map((response) => response?.['data']),
					tap((userData) => {
						placeAdFacade.updateUser(userData);
					}),
					catchError((err) => {
						if (err.status === 400) {
							router.navigate([APP_ROUTE.wildCard], { skipLocationChange: true });
						}

						return throwError(err);
					})
				),

		// get agent list
		agentList: agentsApi.getAgentList('ad').pipe(
			tap((agentList) => {
				const PROFILE = placeAdFacade.getUser();
				const AGENTS_LIST = agentList;

				agentsState.setAgentList(AGENTS_LIST);
			})
		),
	});
};
