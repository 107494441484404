import { Component } from '@angular/core';
import { APP_ROUTE } from '@core/constants';
import { OFFCANVAS_HEADER_SIDENAV } from '@core/constants/offcanvas.constant';
import { AisleType, CountyType, SectionType } from '@core/enums';
import { RouteDataType, RouteService } from '@core/services/route.service';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '@shared/shared.module';
import { HeaderAccountMenuModule } from '../header/components/header-account-menu/header-account-menu.module';
import { HeaderSidenavComponent } from '../header/components/header-sidenav/header-sidenav.component';
import { MatTabsModule } from '@angular/material/tabs';
import { IntercomService } from '@core/services/intercom.service';

interface NavItem {
	label: string;
	icon: string;
	route: string;
	className: string;
	pageReference?: string;
}

@Component({
	standalone: true,
	imports: [SharedModule, MatTabsModule, HeaderAccountMenuModule],
	selector: 'findqo-bottom-navbar',
	templateUrl: './bottom-navbar.component.html',
	styleUrls: ['./bottom-navbar.component.scss'],
})
export class BottomNavbarComponent {
	constructor(
		private ngbOffCanvas: NgbOffcanvas,
		public routeService: RouteService,
		private intercomService: IntercomService
	) {}

	navItems: NavItem[] = [
		{
			label: 'Rent',
			icon: 'home_work',
			route: `/${SectionType.PROPERTIES_FOR_RENT}/${CountyType.DEFAULT}/${AisleType.RENT_RESIDENTIAL}`,
			className: 'rent',
			pageReference: SectionType.PROPERTIES_FOR_RENT,
		},
		{
			label: 'Buy',
			icon: 'real_estate_agent',
			route: `/${SectionType.PROPERTIES_FOR_SALE}/${CountyType.DEFAULT}/${AisleType.SALE_RESIDENTIAL}`,
			className: 'buy',
			pageReference: SectionType.PROPERTIES_FOR_SALE,
		},
		{
			label: 'Place Free Ad',
			icon: 'add_circle',
			route: `/${APP_ROUTE.placeAd}`,
			className: 'place-ad',
			pageReference: 'place-ad',
		},
		{
			label: 'Favorites',
			icon: 'favorite',
			route: `/${APP_ROUTE.favorites}`,
			className: 'favorites',
			pageReference: 'favorites',
		},
	];

	readonly routeDataType = RouteDataType;

	onOpenHeaderSidenav(): void {
		this.intercomService.toggelIntercomPopup(true);
		this.ngbOffCanvas.open(HeaderSidenavComponent, OFFCANVAS_HEADER_SIDENAV);
	}
}
