import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { APP_ROUTE } from '@core/constants';
import { AdPaymentStatus, AdStatus, IconType, PlaceAdType } from '@core/enums';
import { EditAdResponseData } from '@core/models';
import { IntercomService } from '@core/services/intercom.service';
import { ViewportService } from '@core/services/viewport.service';
import { environment } from '@environments/environment';
import { PlaceAdFacade } from '@feature/place-ad/place-ad.facade';
import { PlaceAdService } from '@feature/place-ad/place-ad.service';
import { ButtonType } from '@shared/components/button/button.component';
import { DestroyService } from '@shared/services/destroy.service';
import { Observable, takeUntil } from 'rxjs';
import { HeaderFacade } from '../../header.facade';
import { Location } from '@angular/common';

interface UserAccount {
	name: string;
	imageUrl: string;
	isSelectedAccount: boolean;
	sellerTypeId: string;
}

@Component({
	selector: 'findqo-place-ad-header',
	templateUrl: './place-ad-header.component.html',
	styleUrls: ['./place-ad-header.component.scss'],
	providers: [DestroyService],
})
export class PlaceAdHeaderComponent implements OnInit {
	constructor(
		private destroy: DestroyService,
		public viewport: ViewportService,
		private headerFacade: HeaderFacade,
		private placeAdService: PlaceAdService,
		private placeAdFacade: PlaceAdFacade,
		private router: Router,
		private intercomService: IntercomService,
		private readonly location: Location
	) {
		this.user$ = headerFacade.getUser$();
		this.currentSellerId$ = this.placeAdService.getCurrentSellerId$();
		this.currentTab$ = placeAdService.getTabIndex$();
		this.totalTabs$ = placeAdService.totalTabs$;
		this.editAdData$ = placeAdFacade.editAdData$;
	}

	defaultAvatar = environment.defaultAvatarImg;

	readonly editAdData$: Observable<EditAdResponseData>;
	user$: Observable<any>;
	currentSellerId$: Observable<string>;
	currentTab$: Observable<number>;
	readonly totalTabs$: Observable<number>;
	readonly paymentStatus = AdPaymentStatus;
	readonly iconType = IconType;
	readonly buttonType = ButtonType;
	readonly adStatus = AdStatus;

	selectedAccount: UserAccount;
	hasBusinessAccount: boolean;

	accounts: UserAccount[] = [
		{
			name: '',
			imageUrl: '',
			isSelectedAccount: true,
			sellerTypeId: '',
		},
	];

	onSelectedAccount(account: UserAccount, isSellerUpdated = false): void {
		this.selectedAccount = account;

		if (isSellerUpdated) {
			this.placeAdService.addIndividualControlData(PlaceAdType?.SELLER_TYPE, account.sellerTypeId);

			this.placeAdFacade.updateSellerTypeDetails(account.sellerTypeId);
		}

		// Make the selected account as the isSelectedAccount
		this.accounts.forEach((adItem) => {
			adItem.isSelectedAccount = adItem === account;
		});

		if (isSellerUpdated && account.sellerTypeId) {
			const navigationExtras: NavigationExtras = {
				queryParams: { sellerTypeId: account.sellerTypeId },
			};

			this.router.navigate([], navigationExtras);
		}
	}

	// To find the active account
	findSelectedAccount(): void {
		this.currentSellerId$.pipe(takeUntil(this.destroy)).subscribe((res) => {
			if (res === '1') {
				this.onSelectedAccount(this.accounts[0]);
			}
			if (res === '2') {
				this.onSelectedAccount(this.accounts[1]);
			}
		});
	}

	navigateAway(): void {
		if (this.viewport.mobile) {
			this.location.back();
		} else {
			this.router.navigate([APP_ROUTE.home]);
		}
	}

	getUserAccounts(): void {
		this.headerFacade
			.getUser$()
			.pipe(takeUntil(this.destroy))
			.subscribe((user) => {
				this.accounts[0].name = user.name;
				this.accounts[0].imageUrl = user.picture;
				this.accounts[0].sellerTypeId = user.sellerTypeId;

				// if the user has a business acc a new item is created
				if (user?.businessProfile) {
					if (this.accounts.length === 1) {
						const BUSINESS_ACCOUNT: UserAccount = {
							name: user.businessProfile.name,
							imageUrl: user?.businessProfile?.picture,
							isSelectedAccount: false,
							sellerTypeId: user.businessProfile.sellerTypeId,
						};

						this.accounts.push(BUSINESS_ACCOUNT);
					}

					this.hasBusinessAccount = true;
				} else {
					this.hasBusinessAccount = false;
				}

				this.findSelectedAccount();
			});
	}

	showIntercom(): void {
		this.intercomService.showIntercom();
	}

	ngOnInit(): void {
		this.findSelectedAccount();

		if (this.headerFacade.getUser()?.businessProfile) {
			this.getUserAccounts();
		} else {
			this.headerFacade.loadUser().then(() => {
				this.getUserAccounts();
			});
		}
	}
}
