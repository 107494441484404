import { Component } from '@angular/core';

@Component({
	selector: 'findqo-instagram-icon',
	standalone: true,
	template: `
		<svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g id="Instagram">
				<path
					id="&#60;i class=&#34;fa-brands fa-instagram&#34;&#62;&#60;/i&#62;"
					d="M10.1492 6.15419C8.02047 6.15419 6.30341 7.87125 6.30341 10C6.30341 12.1288 8.02047 13.8458 10.1492 13.8458C12.278 13.8458 13.995 12.1288 13.995 10C13.995 7.87125 12.278 6.15419 10.1492 6.15419ZM10.1492 12.5003C8.77357 12.5003 7.64894 11.379 7.64894 10C7.64894 8.621 8.77022 7.49972 10.1492 7.49972C11.5282 7.49972 12.6495 8.621 12.6495 10C12.6495 11.379 11.5249 12.5003 10.1492 12.5003ZM15.0494 5.99688C15.0494 6.49559 14.6477 6.8939 14.1523 6.8939C13.6536 6.8939 13.2553 6.49225 13.2553 5.99688C13.2553 5.50151 13.657 5.09985 14.1523 5.09985C14.6477 5.09985 15.0494 5.50151 15.0494 5.99688ZM17.5965 6.90729C17.5396 5.70568 17.2651 4.6413 16.3849 3.76436C15.5079 2.88743 14.4435 2.61296 13.2419 2.55272C12.0035 2.48243 8.29158 2.48243 7.05316 2.55272C5.8549 2.60962 4.79052 2.88408 3.91024 3.76102C3.02995 4.63796 2.75884 5.70233 2.69859 6.90394C2.6283 8.14236 2.6283 11.8543 2.69859 13.0927C2.75549 14.2943 3.02995 15.3587 3.91024 16.2356C4.79052 17.1126 5.85155 17.387 7.05316 17.4473C8.29158 17.5176 12.0035 17.5176 13.2419 17.4473C14.4435 17.3904 15.5079 17.1159 16.3849 16.2356C17.2618 15.3587 17.5363 14.2943 17.5965 13.0927C17.6668 11.8543 17.6668 8.14571 17.5965 6.90729ZM15.9966 14.4215C15.7355 15.0775 15.2301 15.583 14.5707 15.8474C13.5833 16.239 11.2404 16.1486 10.1492 16.1486C9.05807 16.1486 6.71176 16.2356 5.72771 15.8474C5.07168 15.5863 4.56627 15.0809 4.30185 14.4215C3.91024 13.4341 4.00061 11.0912 4.00061 10C4.00061 8.90885 3.91359 6.56253 4.30185 5.57849C4.56292 4.92246 5.06833 4.41705 5.72771 4.15263C6.7151 3.76102 9.05807 3.85139 10.1492 3.85139C11.2404 3.85139 13.5867 3.76436 14.5707 4.15263C15.2268 4.4137 15.7322 4.91911 15.9966 5.57849C16.3882 6.56588 16.2978 8.90885 16.2978 10C16.2978 11.0912 16.3882 13.4375 15.9966 14.4215Z"
					fill="#A6AAB3"
				/>
			</g>
		</svg>
	`,
	styleUrl: './instagram-icon.component.scss',
})
export class InstagramIconComponent {}
