import { Platform } from '@angular/cdk/platform';
import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { ActivatedRoute, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterOutlet } from '@angular/router';
import { BottomNavbarComponent } from '@core/components/bottom-navbar/bottom-navbar.component';
import { AdSubmittedPlatform } from '@core/enums';
import { BreakpointObserverService } from '@core/services/breakpoint-observer.service';
import { RouteDataType, RouteService } from '@core/services/route.service';
import { PlaceAdSubmittedModule } from '@feature/place-ad/pages/place-ad-submitted/place-ad-submitted.module';
import { MatchingTenantsFacade } from '@shared/facades/matching-tenants-facade';
import { filter, map, tap } from 'rxjs';
import { FooterModule } from './core/components/footer/footer.module';
import { HeaderModule } from './core/components/header/header.module';
import { DatePickerAdapter } from '@core/adapters';
import { LoaderModule } from '@core/components/loader/loader.module';
import { LoaderService } from '@core/services/loader.service';
import { IntercomService } from '@core/services/intercom.service';
import { APP_ROUTE } from '@core/constants';
import { AuthService } from '@core/data-access/services/auth.service';
import { MyProfileFacade } from '@feature/my-profile/my-profile.facade';
import { SharedModule } from '@shared/shared.module';

@Component({
	selector: 'findqo-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	standalone: true,
	imports: [HeaderModule, RouterOutlet, FooterModule, BottomNavbarComponent, PlaceAdSubmittedModule, LoaderModule, SharedModule],
	providers: [
		{
			provide: DateAdapter,
			useClass: DatePickerAdapter,
			deps: [MAT_DATE_LOCALE, Platform],
		},
	],
})
export class AppComponent implements OnInit {
	constructor(
		@Inject(PLATFORM_ID) private readonly platformId: string,
		public readonly bpoService: BreakpointObserverService,
		private readonly routeService: RouteService,
		private readonly router: Router,
		private readonly activated: ActivatedRoute,
		private readonly matchingTenanstFacade: MatchingTenantsFacade,
		private readonly loading: LoaderService,
		private readonly intercom: IntercomService,
		private readonly auth: AuthService,
		private readonly profileFacade: MyProfileFacade
	) {}

	isCountyListVisible = false;
	readonly routeDataType = RouteDataType;

	private onChangeRoute(): void {
		// Analytics
		this.router.events
			.pipe(
				tap((event) => {
					// handling the route change loader for resolver's
					if (event instanceof NavigationStart) {
						this.loading.setIsLoading(true);
					} else if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
						this.loading.setIsLoading(false);

						if (event.url === APP_ROUTE.home || event.url === APP_ROUTE.contact) {
							this.intercom.bootIntercom();
						} else {
							this.intercom.shutdownIntercom();
						}
					}
				}),
				map(() => this.activated),
				map((route) => {
					while (route.firstChild) route = route.firstChild;
					return route;
				}),
				filter((route) => route.outlet === 'primary')
			)
			.subscribe((route: ActivatedRoute) => {
				this.isCountyListVisible = !!route.snapshot.data?.['isCountyListVisible'];
			});
	}

	ngOnInit(): void {
		this.activated.queryParams.subscribe((query) => {
			// Only generate the deeplink when its an mobile browser.
			if (isPlatformBrowser(this.platformId) && /Mobi|Android/i.test(navigator.userAgent)) {
				const SESSION_ID = query['sessionId'];
				const AD_ID = query['adId'];
				const IS_MOBILE = query['platform'] === AdSubmittedPlatform.MOBILE;

				// TODO: check for prev referrer (&& document.referrer === 'https://checkout.stripe.com/)'
				if (SESSION_ID && AD_ID && IS_MOBILE) {
					const REQUEST_BODY = { sessionId: SESSION_ID, adId: AD_ID };
					this.matchingTenanstFacade.getMobileAppLink(REQUEST_BODY).subscribe((data) => {
						if (data?.['shortLink']) {
							window.location.href = data?.['shortLink'];
						}
					});
				}
			}

			if (isPlatformBrowser(this.platformId) && query['adminToken']) {
				this.auth.logout(false);
				this.auth.login({ accessToken: query['adminToken'] });
				this.profileFacade.autoLoginAdmin();
			}
		});

		this.routeService.init();
		this.onChangeRoute();
	}
}
