import { Component, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { SectionEnum } from '@core/enums/footer.enum';

@Component({
	selector: 'findqo-bottomsheet',
	templateUrl: './bottomsheet.component.html',
	styleUrl: './bottomsheet.component.scss',
})
export class BottomsheetComponent {
	constructor(
		@Inject(MAT_BOTTOM_SHEET_DATA) public readonly data,
		private bottomSheetRef: MatBottomSheetRef<BottomsheetComponent>
	) {}
	readonly sectionEnum = SectionEnum;

	onClose(value = null): void {
		this.bottomSheetRef.dismiss(value);
	}
}
