import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { AuthService } from '../data-access/services/auth.service';

@Injectable({
	providedIn: 'root',
})
export class TokenInterceptor implements HttpInterceptor {
	constructor(private auth: AuthService) {}

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		const USER = this.auth.user();
		const IS_LOGGED_IN = USER?.accessToken;
		const IS_FINDQO_API_URL = request.url.startsWith(environment.apiUrl);
		const IS_TENANTIN_URL = request.url.startsWith(environment.tenantinApiBaseUrl);

		if (IS_LOGGED_IN && (IS_FINDQO_API_URL || IS_TENANTIN_URL)) {
			request = request.clone({
				setHeaders: { Authorization: `Bearer ${USER.accessToken}` },
			});
		}

		return next.handle(request);
	}
}
