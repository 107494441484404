import { HttpClientModule } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';

import { SnackModule } from './components/snack/snack.module';
import { APP_INITIALIZERS } from './initializers';
import { APP_INTERCEPTORS } from './interceptors';
import { DestroyService } from '@shared/services/destroy.service';

/**
 * Add here all modules, components, functionalities, services, etc... that are load or used once in an Angular application.
 */

@NgModule({
	imports: [HttpClientModule, SnackModule],
	providers: [APP_INITIALIZERS, APP_INTERCEPTORS, DestroyService],
})
export class CoreModule {
	// Looks for the module in the parent injector to see if it's already been loaded (only want it loaded once)
	constructor(@Optional() @SkipSelf() coreModule: CoreModule) {
		if (coreModule) {
			throw new Error('CoreModule is already loaded. Import it in the AppModule only.');
		}
	}
}
