<ng-container>
	<div>
		<ng-container *ngIf="isCountyListVisible">
			<findqo-footer-county-list></findqo-footer-county-list>
		</ng-container>

		<findqo-desktop-footer class="d-none d-lg-block"></findqo-desktop-footer>
		<findqo-footer-mobile class="d-lg-none"></findqo-footer-mobile>
	</div>
</ng-container>
