import { Injectable, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ClientUser } from '@core/models';
import { MyProfileFacade } from '@feature/my-profile/my-profile.facade';
import { ProfileDisabledDialogComponent } from '@feature/place-ad/components/profile-disabled-dialog/profile-disabled-dialog.component';
import { Observable, of, switchMap } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class PlaceAdDisabledAccountGuard {
	constructor(
		private dialog: MatDialog,
		private readonly userFacade: MyProfileFacade
	) {}

	canActivate(): Observable<boolean> {
		return this.userFacade.getProfile().pipe(
			switchMap((res: ClientUser) => {
				const IS_ACCOUNT_DISABLED = +res.isDisabled;

				if (IS_ACCOUNT_DISABLED) {
					this.dialog.open(ProfileDisabledDialogComponent, {
						panelClass: 'post-ad-dialog',
					});
				}

				return of(!+IS_ACCOUNT_DISABLED);
			})
		);
	}
}
