import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

/**
 * MatIconRegistry is a service to register and display icons used by the <mat-icon> component.
 *
 * Registers icon URLs by namespace and name.
 * Registers icon set URLs by namespace.
 * Registers aliases for CSS classes, for use with icon fonts.
 * Loads icons from URLs and extracts individual icons from icon sets.
 */

export const initMatIconRegistryFactory = (matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) => {
	return () => {
		// Add here all custom SVG icon that needs to be registered.
		const SVG_ICONS = [
			'bookmark-solid',
			'sliders-solid',
			'xmark-solid',
			'trash-solid',
			'phone-solid',
			'whatsapp',
			'viber',
			'carousel-horizontal',
			'vertical-swiper',
			'cars',
			'heavy-vehicles',
			'number-plates',
			'taxi',
			'accessories-parts',
			'motorcycles',
			'tiktok',
			'telegram',
			'snapchat',
			'footer-whatsapp',
			'buy-commercial',
			'buy-residential',
			'new-projects',
			'rent-commercial',
			'rent-residential',
			'share-residential',
			'left-arrow',
			'share',
			'check',
			'play-video',
			'arrow-back',
			'sort',
			'location',
			'shield',
			'info',
			'language',
			'list-check',
			'view-all-arrows',
			'car-engine',
			'call',
			'copy-link',
			'close',
			'phone-primary',
			'arrow-drop-down',
			'close-2',
			'dropdown',
			'avatar',
			'export',
			'file-upload',
			'lightbulb',
			'email',
			'help-outline',
			'expand-more',
			'close-error-3',
			'add',
			'check-2',
			'more-general-4',
			'timer',
			'inventory',
			'edit',
			'favorite',
			'arrow-drop-down',
			'favorites',
			'delete',
			'warning',
			'info-outlined',
			'preview',
			'share-ad',
			'copy-link-basic-1',
			'warning-amber',
			'access-time',
			'check-circle-success-3',
			'cancel-edit',
			'add-general-4',
			'remove-circle-outlined',
			'content_copy',
		];

		// Regiter the SVG icon using `matIconRegistry`
		SVG_ICONS.forEach((icon) => {
			matIconRegistry.addSvgIcon(icon, domSanitizer.bypassSecurityTrustResourceUrl(`assets/icons/${icon}.svg`));
		});
	};
};
