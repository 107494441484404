import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class LoaderService {
	private readonly _isLoading$ = new Subject<boolean>();

	get isLoading$(): Observable<boolean> {
		return this._isLoading$.asObservable();
	}

	setIsLoading(isLoading: boolean): void {
		this._isLoading$.next(isLoading);
	}
}
