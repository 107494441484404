import { Component, Input } from '@angular/core';
import { DestroyService } from '@shared/services/destroy.service';

@Component({
	selector: 'findqo-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss'],
	providers: [DestroyService],
})
export class FooterComponent {
	@Input() isCountyListVisible;
}
