import { Component, Input } from '@angular/core';
import { FooterFacade } from '../../footer.facade';

@Component({
	selector: 'findqo-area-list',
	templateUrl: './area-list.component.html',
	styleUrl: './area-list.component.scss',
})
export class AreaListComponent {
	constructor(public readonly footerFacade: FooterFacade) {}
	@Input() selectedSection;

	isSeeMore = false;
}
