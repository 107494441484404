import { Platform } from '@angular/cdk/platform';
import { DOCUMENT, isPlatformBrowser, registerLocaleData } from '@angular/common';
import ar from '@angular/common/locales/ar';
import localeKu from '@angular/common/locales/ckb';
import localeKuExtra from '@angular/common/locales/extra/ckb';
import { Inject, PLATFORM_ID, Renderer2, RendererFactory2 } from '@angular/core';
import { ConfigApi } from '@core/data-access/apis/config.api';
import { ConfigState } from '@core/data-access/states/config.state';
import { LocaleDirection, LocaleType } from '@core/enums/locale.enum';
import { StorageType } from '@core/enums/storage.enum';
import { Translations } from '@core/models/translations.model';
import { StorageService } from '@core/services/storage.service';
import { finalize, skip } from 'rxjs';

/**
 * Add here all initialization related to the app locale
 */

export const initLocaleFactory = (
	configState: ConfigState,
	storageService: StorageService,
	rendererFactory: RendererFactory2,
	configApi: ConfigApi,
	document: Document,
	platform: Platform
): any => {
	return () => {
		const INITIALIZER = new LocaleInitializer(configState, storageService, rendererFactory, configApi, document, platform);

		INITIALIZER.init();

		// TODO: Need to be checked if this still needed if can select multiple language (in V2, English is the only language).
		// INITIALIZER.onLocaleChange();
	};
};

class LocaleInitializer {
	constructor(
		private readonly configState: ConfigState,
		private readonly storageService: StorageService,
		private readonly rendererFactory: RendererFactory2,
		private readonly configApi: ConfigApi,
		@Inject(DOCUMENT) private readonly document: Document,
		@Inject(PLATFORM_ID) private readonly platformId: any
	) {
		// Injecting DOM-related dependencies will not work on `APP_INITIALIZER` as DOM is not yet ready on this phase.
		// The solution is to create a renderer manually using the renderer factory.
		this.renderer = this.rendererFactory.createRenderer(null, null);
	}

	private renderer: Renderer2;

	/**
	 * Set the initial value for the app locale
	 */
	async init(): Promise<void> {
		this.registerLocale();

		const LOCALE = this.storageService.getItem(StorageType.LOCALE);
		// let translations = this.storageService.getItem(StorageType.TRANSLATIONS);

		if (!LOCALE) {
			// Set here all related locale data if there's no locale or translations saved in locale storage.
			// Set english as default locale in the locale storage.
			this.storageService.setItem(StorageType.LOCALE, LocaleType.ENGLISH);
			if (isPlatformBrowser(this.platformId)) {
				this.renderer.setAttribute(this.document.body, 'dir', LocaleDirection.LTR);
			}

			try {
				/**
				 * HACK: Load the static translations saved in the constant file while waiting for the Translations API response.
				 * Translations API response time is very slow and affecting the initialization of the APP.
				 */
				// translations = isPlatformBrowser(this.platformId)
				// 	? await firstValueFrom(this.configApi.getTranslations(LocaleType.ENGLISH))
				// 	: INITIAL_TRANSLATIONS;
				// Set the translations on both app state and local storage
				// this.storageService.setItem(StorageType.TRANSLATIONS, JSON.stringify(translations));
				// this.configState.setTranslations(translations);
			} catch {
				// Add error handling here...
			}
		} else {
			// If there's a cached data in local storage, then just saved the data in app state.
			this.configState.setLocale(LOCALE);
			// this.configState.setTranslations(isPlatformBrowser(this.platformId) ? JSON.parse(translations) : INITIAL_TRANSLATIONS);
			this.renderer.setAttribute(this.document.body, 'dir', this.configState.getLocaleDirection(LOCALE));
		}
	}

	/**
	 * Listen to the stream of Locale value
	 */
	onLocaleChange(): void {
		// The first emit of locale$ is not needed on initialize of the app.
		this.configState.locale$.pipe(skip(1)).subscribe((locale: LocaleType) => {
			this.storageService.setItem(StorageType.LOCALE, locale);

			// Get the new translations from the API response based on the selected language.
			this.getTranslations(locale);
		});
	}

	/**
	 * Get translations API call
	 *
	 * @param locale Current app locale
	 */
	private getTranslations(locale: LocaleType): void {
		this.configState.setIsLoading(true);
		this.configApi
			.getTranslations(locale)
			.pipe(finalize(() => this.configState.setIsLoading(false)))
			.subscribe((translations: Translations) => {
				this.storageService.setItem(StorageType.TRANSLATIONS, JSON.stringify(translations));
				window.location.reload();
			});
	}

	/**
	 * Add locale that is not initially registered in Angular
	 *
	 * Registering locale should be always the first one to emit.
	 */
	private registerLocale(): void {
		// Register Kurdistan locale
		registerLocaleData(localeKu, LocaleType.KURDISTAN, localeKuExtra);

		// Regsiter Arabic locale
		registerLocaleData(ar);
	}
}
