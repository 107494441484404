import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class MetaPixelService {
	constructor(
		@Inject(DOCUMENT) private document: Document,
		@Inject(PLATFORM_ID) private platformId: string
	) {}

	private metaPixelInitialized = false;

	initializeMetaPixel(pixelId: string): void {
		if (!isPlatformBrowser(this.platformId) && this.metaPixelInitialized) return;

		(function (f: Window, b, e, v, n, t, s) {
			if (f.fbq) return;
			n = f.fbq = function (...args) {
				n.callMethod ? n.callMethod(...args) : n.queue.push(args);
			};
			if (!f._fbq) f._fbq = n;
			n.push = n;
			n.loaded = true;
			n.version = '2.0';
			n.queue = [];
			t = b.createElement(e);
			t.async = true;
			t.src = v;
			s = b.getElementsByTagName(e)[0];
			s.parentNode.insertBefore(t, s);
		})(window, this.document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');

		window.fbq('init', pixelId);
		window.fbq('track', 'PageView');

		const noScript = this.document.createElement('noscript');
		noScript.innerHTML = `<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=${pixelId}&ev=PageView&noscript=1" />`;
		this.document.body.appendChild(noScript);

		this.metaPixelInitialized = true;
	}
}
