import { Injectable } from '@angular/core';
import { FilterDropdownValue } from '@core/models';
import { BehaviorSubject, Observable } from 'rxjs';
import * as counties from '../../../shared/json/counties/counties.json';

@Injectable()
export class FooterFacade {
	constructor() {}

	private _counties$ = new BehaviorSubject<FilterDropdownValue[]>(null);

	get getCounties$(): Observable<FilterDropdownValue[]> {
		return this._counties$.asObservable();
	}

	getCounties(): FilterDropdownValue[] {
		return this._counties$.getValue();
	}

	fetchCountyList() {
		this._counties$.next(counties?.data?.all);
	}
}
