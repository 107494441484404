import { Component } from '@angular/core';
import { LoaderService } from '@core/services/loader.service';

import { Observable } from 'rxjs';

@Component({
	selector: 'findqo-loader',
	templateUrl: './loader.component.html',
	styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent {
	constructor(private readonly loaderService: LoaderService) {
		this.isLoading$ = this.loaderService.isLoading$;
	}

	isLoading$: Observable<boolean>;
}
