import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { FeatureVersion, LocaleType } from '@core/enums';
import { CommonFacade } from '@core/facades/common.facade';
import { Config } from '@core/models';
import { ButtonType } from '@shared/components/button/button.component';
import { DestroyService } from '@shared/services/destroy.service';
import { Observable } from 'rxjs';
import { IconService } from '@core/services/icon.service';
import { COMPANY_POLICY_LIST } from '../../footer.route';
import { isPlatformBrowser } from '@angular/common';
import { APP_ROUTE } from '@core/constants';
import { environment } from '@environments/environment';

const LANGUAGE_NAVS = [
	{
		locale: LocaleType.ENGLISH,
		value: 'English',
	},
	{
		locale: LocaleType.KURDISTAN,
		value: 'کوردی',
	},
	{
		locale: LocaleType.ARABIC,
		value: 'عربى',
	},
];
@Component({
	selector: 'findqo-desktop-footer',
	templateUrl: './footer-desktop.component.html',
	styleUrls: ['./footer-desktop.component.scss'],
	providers: [DestroyService],
})
export class FooterDesktopComponent {
	constructor(
		public icon: IconService,
		private readonly commonFacade: CommonFacade,
		@Inject(PLATFORM_ID) private readonly platformId: string
	) {
		this.socialLinks$ = commonFacade.getConfig$();
	}

	feedbackUrl = environment.feedbackUrl;
	socialLinks$: Observable<Config>;
	locale: LocaleType = this.commonFacade.getLocale();
	currentYear: number = new Date().getFullYear();

	readonly appRoute = APP_ROUTE;
	readonly featureVersion = FeatureVersion;
	readonly compantPolicyNavList = COMPANY_POLICY_LIST;
	readonly languageNavs = LANGUAGE_NAVS;
	readonly buttonType = ButtonType;
	readonly isPlatformBrowser = isPlatformBrowser(this.platformId);

	onSelectLanguage(locale: LocaleType): void {
		this.commonFacade.setLocale(locale);
	}
}
