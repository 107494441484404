import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../data-access/services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { ProfileDisabledDialogComponent } from '@feature/place-ad/components/profile-disabled-dialog/profile-disabled-dialog.component';
import { ErrorId } from '@core/enums';

@Injectable({
	providedIn: 'root',
})
export class ErrorInterceptor implements HttpInterceptor {
	constructor(
		private auth: AuthService,
		private readonly dialog: MatDialog
	) {}

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		return next.handle(request).pipe(
			catchError((err) => {
				if (err.id === ErrorId.ACCOUNT_DISABLED) {
					this.dialog.open(ProfileDisabledDialogComponent, {
						panelClass: 'post-ad-dialog',
					});
				}

				if (err.status === 401) {
					this.auth.logout();
				}
				return throwError(err);
			})
		);
	}
}
