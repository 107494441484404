<div class="container p-0">
	<div class="row mx-0 px-0 pt-2 pb-3 justify-content-between align-items-center dialog-header">
		<div class="col-auto px-0">
			<h4 class="heading-4-regular fg-general-5 m-0">{{ 'n3871' | translations }}</h4>
		</div>

		<div class="col-auto px-0">
			<button mat-icon-button (click)="onClose(null)" class="close-btn">
				<mat-icon class="justify-content-center fg-general-4 mx-auto fs-20px">close</mat-icon>
			</button>
		</div>
	</div>

	<mat-divider class="-mx-3"></mat-divider>

	<button class="w-100 justify-content-between px-0 option-button" mat-button (click)="onClose(sectionEnum.SELL_RESIDENTIAL)">
		<span>Sale</span>
		<mat-icon class="fg-prim-2 me-0 ms-3" iconPositionEnd *ngIf="data?.selectedSection === sectionEnum.SELL_RESIDENTIAL">check</mat-icon>
	</button>

	<button class="w-100 justify-content-between px-0 option-button" mat-button (click)="onClose(sectionEnum.RENT_RESIDENTIAL)">
		<span>Rent</span>
		<mat-icon class="fg-prim-2 me-0 ms-3" iconPositionEnd *ngIf="data?.selectedSection === sectionEnum.RENT_RESIDENTIAL">check</mat-icon>
	</button>
</div>
