import { NgModule } from '@angular/core';
import { SnackComponent } from './snack.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SharedModule } from '@shared/shared.module';

@NgModule({
	declarations: [SnackComponent],
	imports: [MatSnackBarModule, SharedModule],
	exports: [MatSnackBarModule, SnackComponent],
})
export class SnackModule {}
