<footer class="bg-general-1">
	<div class="container p-4">
		<div class="text-center my-4">
			<a class="brand-name" href="/" ng-reflect-router-link="/">{{ 'n3786' | translations }}</a>

			<span class="d-block fs-14px fw-400 lh-18px fg-general-3 mt-2">{{ 'n3158' | translations }}</span>

			<div class="d-flex align-items-center justify-content-center gap-1 mt-4">
				<div *ngFor="let item of (socialLinks$ | async)?.socialLinks">
					<a [href]="item?.url" target="_blank" class="d-flex align-items-center">
						@if (item?.name.toLowerCase() === 'facebook') {
							<svg height="30" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
								<g id="Facebook">
									<path
										id="&#60;i class=&#34;fa-brands fa-square-facebook&#34;&#62;&#60;/i&#62;"
										d="M16.0387 2.5H4.25302C3.82678 2.5 3.41799 2.66932 3.1166 2.97072C2.8152 3.27212 2.64587 3.6809 2.64587 4.10714V15.8929C2.64587 16.3191 2.8152 16.7279 3.1166 17.0293C3.41799 17.3307 3.82678 17.5 4.25302 17.5H8.84844V12.4003H6.73907V10H8.84844V8.17054C8.84844 6.08962 10.0873 4.94018 11.9847 4.94018C12.8934 4.94018 13.8436 5.10223 13.8436 5.10223V7.14464H12.7967C11.7651 7.14464 11.4433 7.78482 11.4433 8.44141V10H13.7462L13.3779 12.4003H11.4433V17.5H16.0387C16.465 17.5 16.8738 17.3307 17.1752 17.0293C17.4766 16.7279 17.6459 16.3191 17.6459 15.8929V4.10714C17.6459 3.6809 17.4766 3.27212 17.1752 2.97072C16.8738 2.66932 16.465 2.5 16.0387 2.5Z"
										fill="#A6AAB3"
									/>
								</g>
							</svg>
						}

						@if (item?.name.toLowerCase() === 'instagram') {
							<svg height="30" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
								<g id="Instagram">
									<path
										id="&#60;i class=&#34;fa-brands fa-instagram&#34;&#62;&#60;/i&#62;"
										d="M10.1492 6.15419C8.02047 6.15419 6.30341 7.87125 6.30341 10C6.30341 12.1288 8.02047 13.8458 10.1492 13.8458C12.278 13.8458 13.995 12.1288 13.995 10C13.995 7.87125 12.278 6.15419 10.1492 6.15419ZM10.1492 12.5003C8.77357 12.5003 7.64894 11.379 7.64894 10C7.64894 8.621 8.77022 7.49972 10.1492 7.49972C11.5282 7.49972 12.6495 8.621 12.6495 10C12.6495 11.379 11.5249 12.5003 10.1492 12.5003ZM15.0494 5.99688C15.0494 6.49559 14.6477 6.8939 14.1523 6.8939C13.6536 6.8939 13.2553 6.49225 13.2553 5.99688C13.2553 5.50151 13.657 5.09985 14.1523 5.09985C14.6477 5.09985 15.0494 5.50151 15.0494 5.99688ZM17.5965 6.90729C17.5396 5.70568 17.2651 4.6413 16.3849 3.76436C15.5079 2.88743 14.4435 2.61296 13.2419 2.55272C12.0035 2.48243 8.29158 2.48243 7.05316 2.55272C5.8549 2.60962 4.79052 2.88408 3.91024 3.76102C3.02995 4.63796 2.75884 5.70233 2.69859 6.90394C2.6283 8.14236 2.6283 11.8543 2.69859 13.0927C2.75549 14.2943 3.02995 15.3587 3.91024 16.2356C4.79052 17.1126 5.85155 17.387 7.05316 17.4473C8.29158 17.5176 12.0035 17.5176 13.2419 17.4473C14.4435 17.3904 15.5079 17.1159 16.3849 16.2356C17.2618 15.3587 17.5363 14.2943 17.5965 13.0927C17.6668 11.8543 17.6668 8.14571 17.5965 6.90729ZM15.9966 14.4215C15.7355 15.0775 15.2301 15.583 14.5707 15.8474C13.5833 16.239 11.2404 16.1486 10.1492 16.1486C9.05807 16.1486 6.71176 16.2356 5.72771 15.8474C5.07168 15.5863 4.56627 15.0809 4.30185 14.4215C3.91024 13.4341 4.00061 11.0912 4.00061 10C4.00061 8.90885 3.91359 6.56253 4.30185 5.57849C4.56292 4.92246 5.06833 4.41705 5.72771 4.15263C6.7151 3.76102 9.05807 3.85139 10.1492 3.85139C11.2404 3.85139 13.5867 3.76436 14.5707 4.15263C15.2268 4.4137 15.7322 4.91911 15.9966 5.57849C16.3882 6.56588 16.2978 8.90885 16.2978 10C16.2978 11.0912 16.3882 13.4375 15.9966 14.4215Z"
										fill="#A6AAB3"
									/>
								</g>
							</svg>
						}

						@if (item?.name.toLowerCase() === 'linkedin') {
							<svg height="30" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path
									d="M17.4286 3H4.56808C3.97879 3 3.5 3.48549 3.5 4.08147V16.9185C3.5 17.5145 3.97879 18 4.56808 18H17.4286C18.0179 18 18.5 17.5145 18.5 16.9185V4.08147C18.5 3.48549 18.0179 3 17.4286 3ZM8.03348 15.8571H5.81027V8.69866H8.03683V15.8571H8.03348ZM6.92187 7.72098C6.20871 7.72098 5.63281 7.14174 5.63281 6.43192C5.63281 5.7221 6.20871 5.14286 6.92187 5.14286C7.6317 5.14286 8.21094 5.7221 8.21094 6.43192C8.21094 7.14509 7.63504 7.72098 6.92187 7.72098ZM16.3672 15.8571H14.144V12.375C14.144 11.5446 14.1272 10.4766 12.9888 10.4766C11.8304 10.4766 11.6529 11.3806 11.6529 12.3147V15.8571H9.42969V8.69866H11.5625V9.67634H11.5926C11.8906 9.11384 12.6172 8.5212 13.6987 8.5212C15.9487 8.5212 16.3672 10.0045 16.3672 11.933V15.8571Z"
									fill="#A6AAB3"
								/>
							</svg>
						}

						@if (item?.name.toLowerCase() === 'youtube') {
							<div class="ms-1">
								<svg xmlns="http://www.w3.org/2000/svg" height="30px" viewBox="0 0 576 512">
									<path
										d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"
										fill="#A6AAB3"
									/>
								</svg>
							</div>
						}
					</a>
				</div>
			</div>
		</div>
		<mat-divider></mat-divider>

		<div class="text-center my-4">
			<a href="mailto:hello@findqo.ie" class="social-icons">
				<mat-icon class="email-icon">mail_outline</mat-icon>
				<ng-container *ngIf="isPlatformBrowser">
					{{ 'n2316' | translations }}
				</ng-container>
			</a>

			<a class="social-icons mt-3 cursor-pointer" [href]="feedbackUrl" target="_blank">
				<mat-icon class="material-symbols-outlined icon-size">feedback</mat-icon>
				{{ 'n3278' | translations }}
			</a>

			<a class="social-icons mt-3" [routerLink]="appRoute.contact">
				<mat-icon class="material-symbols-outlined icon-size">contact_support</mat-icon>
				<ng-container *ngIf="isPlatformBrowser">
					{{ 'n60' | translations }}
				</ng-container>
			</a>

			<a class="social-icons mt-3" [routerLink]="appRoute.placeAdGuide">
				<mat-icon class="material-symbols-outlined icon-size">help</mat-icon>
				<ng-container *ngIf="isPlatformBrowser">
					{{ 'n3761' | translations }}
				</ng-container>
			</a>
		</div>
		<mat-divider></mat-divider>

		<div class="row mx-0 my-4 justify-content-center">
			<div class="col-auto pe-3" *ngFor="let nav of compantPolicyNavList">
				<a class="w-100 d-block" [routerLink]="nav.path">
					<span class="fg-general-3 fs-14px lh-18px fw-400">{{ nav.data.title | translations }}</span>
				</a>
			</div>
		</div>

		<mat-divider></mat-divider>

		<div class="text-center my-4">
			<a [routerLink]="appRoute.apps" class="social-icons">
				<mat-icon class="download-app">download</mat-icon>
				{{ 'n3279' | translations }}
			</a>
		</div>

		<mat-divider></mat-divider>

		<div class="text-center mt-4">
			<img ngSrc="assets/images/dbi-finalists-2023.png" alt="dbi-finalists-2023" width="90" height="78" />

			<span class="d-block fs-14px fw-400 lh-18px fg-general-3 text-center mt-3">&copy; {{ currentYear }} {{ 'n3472' | translations }}</span>
		</div>
	</div>
</footer>
