import { Component } from '@angular/core';
import { APP_ROUTE } from '@core/constants';
import { FeatureVersion } from '@core/enums';
import { CommonFacade } from '@core/facades/common.facade';
import { BreakpointObserverService } from '@core/services/breakpoint-observer.service';
import { RouteDataType, RouteService } from '@core/services/route.service';
import { HeaderFacade } from './header.facade';

@Component({
	selector: 'findqo-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
	constructor(
		public readonly bpoService: BreakpointObserverService,
		private readonly headerFacade: HeaderFacade,
		private readonly commonFacade: CommonFacade,
		private readonly routeService: RouteService
	) {}

	sections$ = this.commonFacade.getSectionList$();
	locale$ = this.headerFacade.getLocale$();
	translations$ = this.headerFacade.getTranslations$();
	user$ = this.headerFacade.getUser$();
	currenRoute$ = this.routeService.getPreviousUrl$();
	// isShowInstallApp = true;

	readonly routeDataType = RouteDataType;
	readonly featureVersion = FeatureVersion;
	readonly appRoute = APP_ROUTE;
}
