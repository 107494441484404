import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { HeaderComponent } from './header.component';
import { LogoutDialogComponent } from './components/logout-dialog/logout-dialog.component';
import { HeaderNavItemsModule } from './components/header-nav-items/header-nav-item.module';
import { PlaceAdHeaderComponent } from './components/place-ad-header/place-ad-header.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatToolbarModule } from '@angular/material/toolbar';
import { HeaderV2Component } from './v2/header-v2/header-v2.component';
import { HeaderAccountMenuModule } from './components/header-account-menu/header-account-menu.module';
import { ButtonModule } from '@shared/components/button/button.module';
import { MatMenuModule } from '@angular/material/menu';
import { ProfileDisabledDialogModule } from '@feature/place-ad/components/profile-disabled-dialog/profile-disabled-dialog.module';

@NgModule({
	declarations: [HeaderComponent, HeaderV2Component, LogoutDialogComponent, PlaceAdHeaderComponent],
	imports: [
		SharedModule,
		MatDividerModule,
		MatToolbarModule,
		MatMenuModule,
		HeaderNavItemsModule,
		HeaderAccountMenuModule,
		ButtonModule,
		ProfileDisabledDialogModule,
	],
	exports: [HeaderComponent],
})
export class HeaderModule {}
