import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API } from '@core/constants';
import { LocaleType } from '@core/enums';
import { CommonResponse, Config, Translations } from '@core/models';
import { CommonUtil } from '@core/utils/common.util';
import { Observable, catchError, map, of } from 'rxjs';
import * as translationJson from '../../../shared/json/translations/translations.json';
import { StorageService } from '@core/services/storage.service';

@Injectable({
	providedIn: 'root',
})
export class ConfigApi {
	protected constructor(
		private readonly http: HttpClient,
		private storage: StorageService
	) {}

	getTranslations(lang: LocaleType): Observable<Translations> {
		return this.http
			.get<Translations>(API.config.translations, {
				params: CommonUtil.createHttpParams({ lang }),
			})
			.pipe(
				map((response: CommonResponse<Translations>) => response.data),
				catchError((response: HttpErrorResponse) => {
					this.storage.clearStorage();
					throw response;
				})
			);
	}

	getConfig(): Observable<Config> {
		return this.http.get<CommonResponse<Config>>(API.config.startupInfo).pipe(
			map((response: CommonResponse<Config>) => {
				// TODO: Currency in response is on array. Need to coordinate with the server side why it's on array.
				const CURRENCY = response.data.currency[0];

				return {
					...response.data,
					currency: {
						...CURRENCY,
					},
				};
			})
		);
	}

	getJsonTranslation(): Observable<Translations> {
		return of(translationJson);
	}
}
