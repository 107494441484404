import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { FeatureVersion } from '@core/enums';
import { environment } from '@environments/environment';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class MobileGuard  {
	constructor(private readonly device: DeviceDetectorService, private readonly router: Router) {}

	canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		// Only for Iraq version for now.
		if (this.device.isMobile() && environment.featureVersion === FeatureVersion.V1) {
			this.router.navigate(['motors/cars']);

			return false;
		}

		return true;
	}
}
