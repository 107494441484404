import { Component } from '@angular/core';

@Component({
	selector: 'findqo-linkedin-icon',
	standalone: true,
	template: `
		<svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M17.4286 3H4.56808C3.97879 3 3.5 3.48549 3.5 4.08147V16.9185C3.5 17.5145 3.97879 18 4.56808 18H17.4286C18.0179 18 18.5 17.5145 18.5 16.9185V4.08147C18.5 3.48549 18.0179 3 17.4286 3ZM8.03348 15.8571H5.81027V8.69866H8.03683V15.8571H8.03348ZM6.92187 7.72098C6.20871 7.72098 5.63281 7.14174 5.63281 6.43192C5.63281 5.7221 6.20871 5.14286 6.92187 5.14286C7.6317 5.14286 8.21094 5.7221 8.21094 6.43192C8.21094 7.14509 7.63504 7.72098 6.92187 7.72098ZM16.3672 15.8571H14.144V12.375C14.144 11.5446 14.1272 10.4766 12.9888 10.4766C11.8304 10.4766 11.6529 11.3806 11.6529 12.3147V15.8571H9.42969V8.69866H11.5625V9.67634H11.5926C11.8906 9.11384 12.6172 8.5212 13.6987 8.5212C15.9487 8.5212 16.3672 10.0045 16.3672 11.933V15.8571Z"
				fill="#A6AAB3"
			/>
		</svg>
	`,
	styleUrl: './linkedin-icon.component.scss',
})
export class LinkedinIconComponent {}
