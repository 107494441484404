import { Component } from '@angular/core';

@Component({
	selector: 'findqo-facebook-icon',
	standalone: true,
	template: `
		<svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g id="Facebook">
				<path
					id="&#60;i class=&#34;fa-brands fa-square-facebook&#34;&#62;&#60;/i&#62;"
					d="M16.0387 2.5H4.25302C3.82678 2.5 3.41799 2.66932 3.1166 2.97072C2.8152 3.27212 2.64587 3.6809 2.64587 4.10714V15.8929C2.64587 16.3191 2.8152 16.7279 3.1166 17.0293C3.41799 17.3307 3.82678 17.5 4.25302 17.5H8.84844V12.4003H6.73907V10H8.84844V8.17054C8.84844 6.08962 10.0873 4.94018 11.9847 4.94018C12.8934 4.94018 13.8436 5.10223 13.8436 5.10223V7.14464H12.7967C11.7651 7.14464 11.4433 7.78482 11.4433 8.44141V10H13.7462L13.3779 12.4003H11.4433V17.5H16.0387C16.465 17.5 16.8738 17.3307 17.1752 17.0293C17.4766 16.7279 17.6459 16.3191 17.6459 15.8929V4.10714C17.6459 3.6809 17.4766 3.27212 17.1752 2.97072C16.8738 2.66932 16.465 2.5 16.0387 2.5Z"
					fill="#A6AAB3"
				/>
			</g>
		</svg>
	`,
	styleUrl: './facebook-icon.component.scss',
})
export class FacebookIconComponent {}
