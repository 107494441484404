import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class ThemeService {
	constructor(@Inject(DOCUMENT) private document: Document) {}

	private darkMode: boolean = false;

	private detect(): void {
		if (this.darkMode) {
			this.document.body.classList.add('theme-dark');
			this.document.body.classList.remove('theme-light');
		} else {
			this.document.body.classList.add('theme-light');
			this.document.body.classList.remove('theme-dark');
		}
	}

	init(): void {
		this.detect();
	}

	toggle(): void {
		this.darkMode = !this.darkMode;
		this.detect();
	}
}
