import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { FooterComponent } from './footer.component';
import { FooterDesktopComponent } from './components/footer-desktop/footer-desktop.component';
import { FooterMobileComponent } from './components/footer-mobile/footer-mobile.component';
import { FooterCountyListComponent } from './components/footer-county-list/footer-county-list.component';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';
import { MatGridListModule } from '@angular/material/grid-list';
import { ButtonModule } from '@shared/components/button/button.module';
import { AreaListComponent } from './components/area-list/area-list.component';
import { BottomsheetComponent } from './components/bottomsheet/bottomsheet.component';
import { MatDividerModule } from '@angular/material/divider';
import { FooterFacade } from './footer.facade';
import { MatListModule } from '@angular/material/list';
import { HeaderSidenavComponent } from '../header/components/header-sidenav/header-sidenav.component';
import { YoutubeIconComponent } from '@shared/components/icons/youtube/youtube-icon.component';
import { InstagramIconComponent } from '@shared/components/icons/instagram/instagram-icon.component';
import { LinkedinIconComponent } from '@shared/components/icons/linkedin/linkedin-icon.component';
import { FacebookIconComponent } from '@shared/components/icons/facebook/facebook-icon.component';

@NgModule({
	declarations: [
		FooterComponent,
		FooterDesktopComponent,
		FooterMobileComponent,
		FooterCountyListComponent,
		AreaListComponent,
		BottomsheetComponent,
		HeaderSidenavComponent,
	],
	imports: [
		SharedModule,
		MatListModule,
		MatDividerModule,
		ButtonModule,
		MatMenuModule,
		MatTabsModule,
		MatGridListModule,
		MatBottomSheetModule,
		YoutubeIconComponent,
		InstagramIconComponent,
		LinkedinIconComponent,
		FacebookIconComponent,
	],
	exports: [FooterComponent],
	providers: [FooterFacade],
})
export class FooterModule {}
