import { ApplicationConfig, importProvidersFrom } from '@angular/core';

import { provideHttpClient } from '@angular/common/http';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { CoreModule } from '@core/core.module';
import { AppRoutingModule } from './app-routing.module';

export const appConfig: ApplicationConfig = {
	providers: [importProvidersFrom(BrowserModule, AppRoutingModule, CoreModule), provideClientHydration(), provideAnimations(), provideHttpClient()],
};
