import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { ABOUT_META_CONTENTS, APP_ROUTE, AGENT_LANDING_META_CONTENTS, CONTACT_US_SEO, HOME_META_CONTENTS, PLACE_AD_GUIDE_META_CONTENTS } from '@core/constants';
import { AuthGuard } from '@core/guards/auth.guard';
import { BussinessProfileGuard } from '@core/guards/business-profile.guard';
import { MobileGuard } from '@core/guards/mobile.guard';
import { PlaceAdDisabledAccountGuard } from '@core/guards/place-ad/place-ad-disabled-account.guard';
import { CanonicalGuard } from '@core/guards/seo/canonical.guard';
import { RobotsGuard } from '@core/guards/seo/robots.guard';
import { SeoGuard } from '@core/guards/seo/seo.guard';
import { RouteDataType } from '@core/services/route.service';
import { EditAdResolver } from '@feature/place-ad/resolvers/edit-ad-resolver';
import { PlaceAdPageResolver } from '@feature/place-ad/resolvers/place-ad-page.resolver';

const routes: Routes = [
	{
		path: '',
		data: { title: HOME_META_CONTENTS.title, description: HOME_META_CONTENTS.description, isCountyListVisible: true },
		loadChildren: () => import('@feature/home/home.module').then((m) => m.HomePageModule),
		canActivate: [SeoGuard, MobileGuard],
	},
	{
		path: APP_ROUTE.terms,
		data: { title: 'n61' },
		loadChildren: () => import('./feature/company-policy/company-policy.module').then((m) => m.CompanyPolicyModule),
		canActivate: [SeoGuard, RobotsGuard],
	},
	{
		path: APP_ROUTE.cookiePolicy,
		data: { title: 'n3124' },
		loadChildren: () => import('./feature/company-policy/company-policy.module').then((m) => m.CompanyPolicyModule),
		canActivate: [SeoGuard, RobotsGuard],
	},
	{
		path: APP_ROUTE.privacyPolicy,
		data: { title: 'n62' },
		loadChildren: () => import('./feature/company-policy/company-policy.module').then((m) => m.CompanyPolicyModule),
		canActivate: [SeoGuard, RobotsGuard],
	},
	{
		path: APP_ROUTE.about,
		data: { title: 'n58', description: ABOUT_META_CONTENTS.description },
		loadChildren: () => import('./feature/about/about.module').then((m) => m.AboutModule),
		canActivate: [SeoGuard],
	},
	{
		path: APP_ROUTE.contact,
		data: { title: CONTACT_US_SEO.title, description: CONTACT_US_SEO.description },
		loadChildren: () => import('./feature/contact-us/contact-us.module').then((m) => m.ContactUsModule),
		canActivate: [SeoGuard],
	},
	{
		path: APP_ROUTE.placeAdGuide,
		data: { title: PLACE_AD_GUIDE_META_CONTENTS.title, description: PLACE_AD_GUIDE_META_CONTENTS.description },
		loadChildren: () => import('./feature/place-ad-guide/place-ad-guide.module').then((m) => m.PlaceAdGuideModule),
		canActivate: [SeoGuard],
	},
	{
		path: APP_ROUTE.apps,
		data: { title: 'n3584' },
		loadChildren: () => import('./feature/download-apps/download-apps.module').then((m) => m.DownloadAppsPageModule),
		canActivate: [SeoGuard],
	},
	// {
	// 	path: APP_ROUTE.playground,
	// 	loadChildren: () => import('./feature/playground/playground.module').then((m) => m.PlaygroundPageModule),
	// },
	{
		path: APP_ROUTE.placeAd,
		loadChildren: () => import('./feature/place-ad/place-ad.module').then((m) => m.PlaceAdModule),
		canActivate: [AuthGuard, SeoGuard, RobotsGuard, PlaceAdDisabledAccountGuard],
		data: {
			title: 'n20',
			[RouteDataType.IS_HEADER_VISIBLE]: false,
			[RouteDataType.IS_FOOTER_VISIBLE]: false,
			[RouteDataType.IS_PLACE_AD_HEADER_VISIBLE]: true,
			[RouteDataType.PAGE_REFERENCE]: 'place-ad',
		},
		resolve: { placeAdResolver: PlaceAdPageResolver },
	},
	{
		path: `${APP_ROUTE.editAd}/:adId`,
		loadChildren: () => import('./feature/place-ad/pages/place-ad-steps/place-ad-steps.module').then((m) => m.PlaceAdStepsModule),
		canActivate: [AuthGuard, RobotsGuard],
		resolve: { editAdDetails: EditAdResolver },
		data: {
			[RouteDataType.IS_HEADER_VISIBLE]: false,
			[RouteDataType.IS_FOOTER_VISIBLE]: false,
			[RouteDataType.IS_PLACE_AD_HEADER_VISIBLE]: true,
		},
	},
	{
		path: APP_ROUTE.placeAdComplete,
		loadChildren: () => import('./feature/place-ad/pages/place-ad-submitted/place-ad-submitted.module').then((m) => m.PlaceAdSubmittedModule),
		canActivate: [AuthGuard, RobotsGuard],
		data: {
			[RouteDataType.IS_HEADER_VISIBLE]: false,
			[RouteDataType.IS_FOOTER_VISIBLE]: false,
		},
	},
	{
		path: APP_ROUTE.myAdList,
		data: { title: 'n5' },
		loadChildren: () => import('./feature/my-ads/my-ads.module').then((m) => m.MyAdsModule),
		canActivate: [AuthGuard, SeoGuard, RobotsGuard],
	},

	{
		path: APP_ROUTE.myProfile,
		data: { [RouteDataType.IS_FOOTER_VISIBLE]: false },
		loadChildren: () => import('./feature/my-profile/my-profile.module').then((m) => m.MyProfilePageModule),
		canActivate: [AuthGuard, RobotsGuard],
	},
	{
		path: APP_ROUTE.favorites,
		data: { title: 'n3' },
		loadChildren: () => import('./feature/favorites/favorites.module').then((m) => m.FavoritesPageModule),
		canActivate: [AuthGuard, SeoGuard, RobotsGuard],
	},
	{
		path: APP_ROUTE.seller(),
		loadChildren: () => import('./feature/seller/seller.module').then((m) => m.SellerModule),
	},
	{
		path: APP_ROUTE.pricing,
		data: { title: 'n3318' },
		loadChildren: () => import('./feature/pricing/pricing.module').then((m) => m.PricingPageModule),
		canActivate: [SeoGuard],
	},
	{
		path: APP_ROUTE.myEnquiries,
		data: { title: 'n3538' },
		loadChildren: () => import('./feature/my-enquiries/my-enquiries.module').then((m) => m.MyEnquiriesModule),
		canActivate: [AuthGuard, SeoGuard, RobotsGuard],
	},
	{
		path: APP_ROUTE.meetingSchedule.base,
		data: { title: 'n3917', [RouteDataType.IS_HEADER_VISIBLE]: true, [RouteDataType.IS_FOOTER_VISIBLE]: false },
		loadChildren: () => import('./feature/meeting-schedule/meeting-schedule.module').then((m) => m.MeetingScheduleModule),
		canActivate: [AuthGuard, SeoGuard, RobotsGuard],
	},
	{
		path: APP_ROUTE.unSubscribeEmail,
		data: { title: 'n3964', [RouteDataType.IS_FOOTER_VISIBLE]: false, [RouteDataType.IS_HEADER_VISIBLE]: false },
		loadChildren: () => import('./feature/unsubscribe-email/unsubscribe-email.module').then((m) => m.UnsubscribeEmailModule),
		canActivate: [SeoGuard, RobotsGuard],
	},
	{
		path: APP_ROUTE.emailInvitation,
		redirectTo: '',
		pathMatch: 'full',
	},

	// Public routes
	// TODO: Create a separate file for public routes
	{
		path: APP_ROUTE.public.base,
		children: [
			{
				path: APP_ROUTE.public.seller(),
				loadChildren: () => import('@public/seller/seller.public.module').then((m) => m.PublicSellerPageModule),
				canActivate: [CanonicalGuard],
			},
			{
				path: APP_ROUTE.public.propertyAdDetail,
				loadChildren: () => import('@public/ad-detail/ad-detail.public.module.').then((m) => m.PublicAdDetaiPageModule),
				canActivate: [CanonicalGuard],
			},
		],
	},
	// end - Public routes

	// Agent routes
	{
		path: APP_ROUTE.agentHub.landing,
		data: { title: AGENT_LANDING_META_CONTENTS.title, description: AGENT_LANDING_META_CONTENTS.description, [RouteDataType.IS_AGENT_LANDING_PAGE]: true },
		loadChildren: () => import('./feature/agent-landing/agent-landing.module').then((m) => m.AgentLandingModule),
		canActivate: [SeoGuard, CanonicalGuard],
	},
	{
		path: APP_ROUTE.agentHub.dashboard,
		data: {
			title: 'n3293',
			[RouteDataType.IS_FOOTER_VISIBLE]: false,
			[RouteDataType.IS_HEADER_VISIBLE]: false,
			[RouteDataType.IS_MOBILE_HEADER_VISIBLE]: false,
		},
		loadChildren: () => import('./feature/agent-hub/agent-hub.module').then((m) => m.AgentHubModule),
		canActivate: [SeoGuard, AuthGuard, BussinessProfileGuard],
	},
	// end - Agent routes

	{
		path: 'meetings/:userName/:meetingTitle',
		loadChildren: () => import('@feature/meeting-schedule/pages/view-meeting/view-meeting.module').then((m) => m.ViewMeetingModule),
		canActivate: [SeoGuard],
		data: {
			[RouteDataType.IS_HEADER_VISIBLE]: false,
			[RouteDataType.IS_FOOTER_VISIBLE]: false,
			title: 'n3917',
		},
	},

	{
		path: APP_ROUTE.meetingSchedule.inactive,
		loadChildren: () => import('@feature/meeting-schedule/pages/inactive-meeting/inactive-meeting.module').then((m) => m.InactiveMeetingModule),
		data: {
			[RouteDataType.IS_HEADER_VISIBLE]: false,
			[RouteDataType.IS_FOOTER_VISIBLE]: false,
			title: 'n3942',
		},
	},

	{
		path: APP_ROUTE.propertyAdDetail(),
		loadChildren: () => import('@feature/ad-detail/ad-detail.module').then((m) => m.AdDetailPageModule),
		canActivate: [CanonicalGuard],
	},
	{
		path: ':section/:location/:aisle',
		loadChildren: () => import('./feature/ads/ads.module').then((m) => m.AdsModule),
	},
	{
		path: APP_ROUTE.wildCard,
		loadChildren: () => import('./feature/page-not-found/page-not-found.module').then((m) => m.PageNotFoundPageModule),
		pathMatch: 'full',
		canActivate: [RobotsGuard],
	},
];

@NgModule({
	// Preload strategy gives us an ability to load all or some of modules in the background.
	// Params inheritance strategy is to get the parent params to child routes.
	// Bind to component inputs is to enables binding information from the Router state directly to the inputs of the component in Route configurations.
	imports: [
		RouterModule.forRoot(routes, {
			preloadingStrategy: PreloadAllModules,
			enableViewTransitions: true,
			// initialNavigation: 'enabledBlocking',
			paramsInheritanceStrategy: 'always',
			scrollPositionRestoration: 'enabled',
			bindToComponentInputs: true,
		}),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}
