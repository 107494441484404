import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, catchError, forkJoin, of, tap, throwError } from 'rxjs';
import { PlaceAdApi } from '@core/data-access/apis/place-ad.api';
import { AgentsApi } from '@core/data-access/apis/agents.api';
import { AgentsState } from '@shared/states/agents.stats';
import { ConfigState } from '@core/data-access/states/config.state';
import { APP_ROUTE } from '@core/constants';
import { MyProfileFacade } from '@feature/my-profile/my-profile.facade';

export const EditAdResolver: ResolveFn<any> = (
	route: ActivatedRouteSnapshot,
	state: RouterStateSnapshot,
	router: Router = inject(Router),
	placeAdApi: PlaceAdApi = inject(PlaceAdApi),
	agentsApi: AgentsApi = inject(AgentsApi),
	agentsState: AgentsState = inject(AgentsState),
	configState: ConfigState = inject(ConfigState),
	myProfileFacade: MyProfileFacade = inject(MyProfileFacade)
): Observable<any> => {
	return forkJoin({
		editData: route?.params['adId']
			? placeAdApi.editAd(route.params['adId']).pipe(
					catchError((err) => {
						router.navigate([APP_ROUTE.wildCard], { skipLocationChange: true });

						return throwError(err);
					})
				)
			: of(null),
		agentList: agentsApi.getAgentList('ad').pipe(
			tap((agentList) => {
				const PROFILE = myProfileFacade.getUser();
				const AGENTS_LIST = agentList;

				agentsState.setAgentList(AGENTS_LIST);
			})
		),
	});
};
