import { DOCUMENT } from '@angular/common';
import { APP_INITIALIZER, FactoryProvider, PLATFORM_ID, RendererFactory2 } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ConfigApi } from '@core/data-access/apis/config.api';
import { FiltersApi } from '@core/data-access/apis/filters.api';
import { AuthService } from '@core/data-access/services/auth.service';
import { ConfigState } from '@core/data-access/states/config.state';
import { ProfileState } from '@core/data-access/states/profile.state';
import { SectionsState } from '@core/data-access/states/sections.state';
import { BreakpointObserverService } from '@core/services/breakpoint-observer.service';
import { CanonicalService } from '@core/services/canonical.service';
import { MetaPixelService } from '@core/services/meta-pixel.service';
import { StorageService } from '@core/services/storage.service';
import { ViewportService } from '@core/services/viewport.service';
import { ThemeService } from '../services/theme.service';
import {
	initBreakpointObserverViewportFactory,
	initConfigFactory,
	initProfileStateFactory,
	initSectionListFactory,
	initSeoFactory,
	initThemeFactory,
	initializeMetaPixelFactory,
} from './app.initializer';
import { initLocaleFactory } from './locale.initializer';
import { initMatIconRegistryFactory } from './mat-icon.initializer';
import { MatDialog } from '@angular/material/dialog';
import { DestroyService } from '@shared/services/destroy.service';
import { MyProfileFacade } from '@feature/my-profile/my-profile.facade';

export const APP_INITIALIZERS: FactoryProvider[] = [
	{
		provide: APP_INITIALIZER,
		useFactory: initConfigFactory,
		deps: [ConfigApi, ConfigState, StorageService, PLATFORM_ID, MatDialog, DestroyService, AuthService],
		multi: true,
	},
	{
		provide: APP_INITIALIZER,
		useFactory: initThemeFactory,
		deps: [ThemeService],
		multi: true,
	},
	{
		provide: APP_INITIALIZER,
		useFactory: initBreakpointObserverViewportFactory,
		deps: [ViewportService, BreakpointObserverService],
		multi: true,
	},
	{
		provide: APP_INITIALIZER,
		useFactory: initLocaleFactory,
		deps: [ConfigState, StorageService, RendererFactory2, ConfigApi, DOCUMENT, PLATFORM_ID],
		multi: true,
	},
	{
		provide: APP_INITIALIZER,
		useFactory: initMatIconRegistryFactory,
		deps: [MatIconRegistry, DomSanitizer],
		multi: true,
	},
	{
		provide: APP_INITIALIZER,
		useFactory: initSectionListFactory,
		deps: [SectionsState, FiltersApi],
		multi: true,
	},
	{
		provide: APP_INITIALIZER,
		useFactory: initProfileStateFactory,
		deps: [MyProfileFacade, PLATFORM_ID, AuthService],
		multi: true,
	},
	{
		provide: APP_INITIALIZER,
		useFactory: initSeoFactory,
		deps: [Router, CanonicalService, Meta],
		multi: true,
	},
	{
		provide: APP_INITIALIZER,
		useFactory: initializeMetaPixelFactory,
		deps: [MetaPixelService, PLATFORM_ID],
		multi: true,
	},
];
