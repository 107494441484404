<div class="row gap-2 row-cols-5 justify-content-between align-content-baseline" [ngClass]="{ 'area-container': !isSeeMore }">
	@for (county of footerFacade.getCounties$ | async; track county) {
		<div class="col">
			<a class="text-caption-regular fg-general-4 text-decoration-none" [routerLink]="county.reference | countyLink: selectedSection">
				Properties for {{ selectedSection | sectionText }} in {{ county.name }}
			</a>
		</div>
	}
</div>

<div *ngIf="{ counties: footerFacade.getCounties$ | async } as vm">
	<div class="row align-items-center gap-3" *ngIf="vm?.counties?.length > 45">
		<div class="col">
			<mat-divider></mat-divider>
		</div>

		<div class="col-auto">
			<button class="pe-0 ps-1" mat-button color="primary w-100 text-caption-regular" (click)="isSeeMore = !isSeeMore">
				{{ (isSeeMore ? 'n3091' : 'n3090') | translations }}

				<mat-icon class="ms-1" iconPositionEnd>{{ isSeeMore ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</mat-icon>
			</button>
		</div>
	</div>
</div>
